@import "@/app/styles/_variables.scss";

.loadingContainer {
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100vh;

  &__rainbow {
    position: relative;
    height: 11rem;
    width: 11rem;

    .line {
      position: absolute;
      transform: rotate(45deg);
      width: 10rem;
      height: 10rem;
      top: 0.48rem;
      left: 0.48rem;
      border: 0.48rem solid transparent;
      border-radius: 50%;

      &:nth-child(1) {
        animation: 1.4s rotate 1.0555555556s ease-in-out infinite;
        border-top-color: $text-color-white;
        border-left-color: $text-color-white;
      }

      &:nth-child(2) {
        animation: 1.4s rotate 1.1111111111s ease-in-out infinite;
        border-top-color: $color-secondary-1;
        border-left-color: $color-secondary-1;
      }

      &:nth-child(3) {
        animation: 1.4s rotate 1.1666666667s ease-in-out infinite;
        border-top-color: $color-primary-2;
        border-left-color: $color-primary-2;
      }

      &:nth-child(4) {
        animation: 1.4s rotate 1.2222222222s ease-in-out infinite;
        border-top-color: $color-secondary-1;
        border-left-color: $color-secondary-1;
      }

      &:nth-child(5) {
        animation: 1.4s rotate 1.2777777778s ease-in-out infinite;
        border-top-color: $color-primary-3;
        border-left-color: $color-primary-3;
      }

      &:nth-child(6) {
        animation: 1.4s rotate 1.3333333333s ease-in-out infinite;
        border-top-color: $color-secondary-1;
        border-left-color: $color-secondary-1;
      }

      &:nth-child(7) {
        animation: 1.4s rotate 1.3888888889s ease-in-out infinite;
        border-top-color: $color-primary-1;
        border-left-color: $color-primary-1;
      }
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 1px solid $color-secondary-1;
    background: $color-primary-1;
    padding: 0.5em;
    height: 9rem;
    width: 9rem;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(405deg);
  }
  100% {
    transform: rotate(765deg);
  }
}

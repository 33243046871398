.portfolio {
  margin: 2rem auto;
  width: 90%;
  max-width: 1200px;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .section {
    margin-bottom: 2rem;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;

    h3 {
      margin-bottom: 1rem;
      color: #333;
    }
  }

  .item {
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid #eee;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    h5 {
      margin: 0;
      font-size: 1.2rem;
      color: #555;
    }

    h6 {
      margin: 0.5rem 0;
      font-size: 1rem;
      color: #777;
    }

    p {
      margin: 0.5rem 0;
      font-size: 0.9rem;
      color: #333;

      .feedbackType {
        font-family: Helvetica, sans-serif;
        font-size: 0.9rem;
        color: rgb(111, 118, 249);
      }

      .rating {
        font-family: Helvetica, sans-serif;
        font-size: 0.9rem;
        color: rgb(111, 118, 249);
      }
    }

    time {
      display: block;
      margin-top: 0.5rem;
      font-size: 0.8rem;
      color: #888;
    }
  }
}
